import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import "../dev-styles.scss";
import { ButtonContainer } from "../../../components/mdx/mdx-button-container/index.tsx";
import { SkillStack, addColorToSkills } from "../../../components/skillStack/index.tsx";
export const buttons = [{
  text: "live site",
  url: "https://colorgenerator.arohandutt.com/"
}];
export const tags = ["React", "Vercel", "SCSS"];
export const _frontmatter = {};
const layoutProps = {
  buttons,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className='mdx-default-wrapper'>
      <h1>{`Was Not The Impostor`}</h1>
      <p>{`Based on the popular game Among Us, I used DNS wildcarding along with other network configurations to create a site where users could generate their own "eject screens". Simply head to `}{`[name]`}{`.wasnottheimpostor.com to generate a new site.`}</p>
      <p>{`This project statyed on the front page of the webdev subreddit for about a day, and has been one of my highest trafficked sites.`}</p>
      <ButtonContainer buttons={buttons} mdxType="ButtonContainer" />
      <SkillStack skills={addColorToSkills(tags)} mdxType="SkillStack" />
      <iframe className="mdx-video mdx-iframe" src="https://www.youtube.com/embed/7rhAApBO2oY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      